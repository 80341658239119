import {createApp, PropType, ref} from 'vue';
import {connectVue} from '../modules/configureBugsnag';
import configureGlobalComponents from '../modules/configureGlobalComponents';
import useVuelidate from '@vuelidate/core';
import {email, helpers, required} from '@vuelidate/validators';

const app = createApp({
    props: {
        formData: {type: Object as PropType<any>},
    },
    data() {
        return {
            vuelidateExternalResults: {
                email: [],
            },
        };
    },
    setup(props) {
        const state = {
            email: ref(props.formData.email || ''),
            password: ref(''),
        };
        const rules = {
            email: {
                required: helpers.withMessage('Please enter your email', required),
                email,
            },
            password: {
                required: helpers.withMessage('Please enter your password', required),
            },
        };
        const v = useVuelidate(rules, state);

        async function submitChecks(e: Event) {
            e.preventDefault();
            e.stopPropagation();

            const validationPassed = await v.value.$validate();
            if (validationPassed) {
                (document.getElementById('loginForm') as HTMLFormElement).submit();
            }
        }

        return {
            email: state.email,
            password: state.password,
            v$: v,
            submitChecks,
        };
    },
}, window.propsData);

connectVue(app);
configureGlobalComponents(app);
app.mount('#app');
